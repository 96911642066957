import { ABTests } from "../services/ab-tests";
import { getHostname } from "./get-hostname";

const arenaIFramedGames = [
    '9-ball-pool', 'price-is-right-plinko', 'jigsaw-blast',
    'jewel-shuffle', 'texas-holdem-poker-sit-and-go', 'klondike-solitaire',
    'pyramid-solitaire', 'tri-peaks-solitaire', 'daily-solitaire',
    'texas-holdem-tournament', 'chess', 'tap-zap-boom', 'word-scramble',
    'mahjong-remix', 'spider-solitaire', 'classic-solitaire',
    'mahjong', 'mahjongg-solitaire', 'daily-sudoku', 'daily-diagonal-sudoku', 'sudoku',
    'mahjongg-dimensions', 'candy-mahjongg', 'mahjongg-dark-dimensions', 'sweet-shuffle',
    'daily-jigsaw', 'jigsaw', 'block-champ', 'codeword', '5roll', 'ballistic',
    'best-free-themed-puzzles', 'blackjack', 'bubble-dragons', 'bubble-dragons-saga',
    'bridge', 'freecell-solitaire', 'earth-day-mahjong', 'easy-crossword', 'forty-thieves-solitaire',
    'golf-solitaire', 'daily-crossword', 'daily-crossword-mini', 'daily-american-crossword',
    'daily-quick-crossword', 'mahjong-dimensions-christmas-in-july', 'mah-jongg-age-of-alchemy',
    'mahjongg-toy-chest', 'math-doku', 'mothers-day-mahjong-solitaire', 'pool', 'ten-x-ten',
    'true-trivia-game', 'womens-day-mahjong', '2048', 'trizzle-halloween', 'word-wipe',
    'holiday-mahjong-dimensions', 'jungle-legend', 'knife-smash', 'mahjongg-candy-cane',
    'thanksgiving-mahjong', 'backgammon', 'hard-crossword', 'stans-daily-crossword',
    'sunday-crossword', 'cryptic-crossword-by-cincinnus', 'cryptic-crossword-by-orlando',
    'daily-cryptic-crossword', 'penny-dell-fill-ins', 'the-sunday-crossword', 'addiction-solitaire',
    'alus-revenge-2', 'anagram-crossword', 'canfield-solitaire', 'commuter-crossword',
    'crescent-solitaire', 'crucigramas-dificiles', 'crucigramas-faciles', 'daily-word-search',
    'flagday-freecell-solitaire', 'joseph-crossword', 'landing-party', 'penny-dell-sudoku',
    'penny-dell-word-search', 'polymath-crossword-by-cincinnus', 'premier-crossword',
    'sheffer-crossword', 'slots-hollywood-dreams', 'wander-words', 'arkadium-fill-ins',
];

const arkcomIFramedGamaes = [
    'free-mahjong-game', 'mahjongg-dimensions', 'mahjongg-solitaire', 'daily-crossword', 'classic-solitaire', 'candy-mahjongg', 'spider-solitaire', 'daily-word-search', 'jewel-shuffle', 'mahjongg-dark-dimensions', 'sudoku', 'best-free-themed-puzzles', '9-ball-pool', 'klondike-solitaire', 'ten-x-ten', 'mahjongg-dimensions-candy', 'daily-crossword-commuter', 'free-freecell-solitaire', 'joseph-crossword-kingsfeatures', 'the-daily-jigsaw', 'sheffer-crossword-kingsfeatures', 'arkadium-codeword', 'stan-newmans-easy-crossword', 'scramble-words-game',
];

export const shouldRunOutstream = (compoId: string) => {
  if (compoId !== 'ark_display_r2') {
      return false;
  }

  if (getHostname() === 'games.metv.com') {
      return false;
  }

  const dynamicList = window.STORE && window.STORE.getState().iframeGamesList;
  const gameList = dynamicList || window.location.href.includes('www.arkadium.com') ? arkcomIFramedGamaes : arenaIFramedGames;
  const isGameIFramed = gameList.some(v => window.location.pathname.includes(v));

  return isGameIFramed;
};

export const shouldRunOwnOutstream = () => {
  return ABTests.run('outstream') === 'own';
};

import { Config } from './config-service';

export class CleanIO {
  constructor(protected load: (url: string) => Promise<any>) {}
  init({ isCleanIOEnabled }: Config) {
    if (isCleanIOEnabled) {
      this.load('https://cadmus.script.ac/d5d56pqwoxts5/script.js');
    }
  }
}

const variations = {
    outstream: {
        'www.arkadium.com': [
            { weight: 8, value: 'rise' },
            { weight: 2, value: 'own' },
        ],
        defaultValue: [
            { weight: 1, value: 'own' },
        ],
    },
};

const _localCache: any = {};

function getLs(key: string, defaultValue: string) {
    try {
        const ls = window.localStorage;
        const value = ls.getItem(key);
        if (value) {
            _localCache[key] = value;
            return value;
        }

        _localCache[key] = defaultValue;
        ls.setItem(key, defaultValue);
        return defaultValue;
    } catch (e) {
        _localCache[key] = defaultValue;
        return defaultValue;
    }
}

function cumulativeDistribution(arr: any[]) {
    const weightSum = arr.reduce((acc, curr) => acc + curr.weight, 0);
    const point = Math.random() * weightSum;
    let cumulativeWeight = 0;
    let idx = 0;
    for (let i = 0; i < arr.length; i++) {
        cumulativeWeight += arr[i].weight;
        if (cumulativeWeight > point) {
            idx = i;
            break;
        }
    }
    return arr[idx];
}

export const ABTests = {
    run(name: keyof typeof variations) {
        const local = _localCache[name];
        if (local) return local;

        const hostPredicate = (k: string) => (new RegExp(k)).test(document.location.href);
        const allVars = variations[name];
        const vars = allVars[Object.keys(allVars).find(hostPredicate) as keyof typeof variations[typeof name]] || allVars.defaultValue;

        if (!vars) return '';

        const newValue = cumulativeDistribution(vars).value;
        return getLs(`adsab1_${name}`, newValue);
    },

    keyValue() {
        return `os:${ABTests.run('outstream')}`;
    },
};

const maybe = <T>(fn: () => T) => {
  try {
    return fn();
  } catch (e) {
    return;
  }
};

function getHostnameDirty() {
  const { hostname } = window.location;

  // Ad is not in iFrame
  if (window === window.top) return hostname;

  // Ad is in XML iFrame
  if (hostname.includes('arkadiumarena.com') || hostname.includes('rtl.de')) return hostname;

  // Ad is in Other iFrame (they-sell passback, etc.)
  return maybe(() => window.top?.location.hostname)
    || maybe(() => (new URL(document.referrer)).hostname)
    || 'default';
}

export function getHostname() {
  const hostname = getHostnameDirty();
  return hostname.includes('googlesyndication') ? 'safeframe.googlesyndication.com' : hostname;
}


// @ts-expect-error moduleResolution:nodenext issue 54523
import { Eye } from '@arkadium/eye';

import { AdUnit } from './ads-service';
import { loadScript } from '../shared/loadScript';
import { KeyValues } from './keyvalues';

declare global {
  interface Window {
    googletag?: any;
  }
}

interface IAdServerParams {
  dfpNetworkCode: string;
  inventoryCode: string;
  mcm?: string;
}

const GTAG_TIMEOUT = 10000;

export class AdServer {
  protected kv: KeyValues;
  protected slotMap: any = {};
  protected gtagLoaded: Promise<any>;

  public dependencies = {
    scripts: [ 'https://securepubads.g.doubleclick.net/tag/js/gpt.js' ],
  };

  constructor(protected params: IAdServerParams) {
    loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
    this.kv = new KeyValues(() => location.href, window.localStorage, document.cookie);
    if (!window.googletag) {
      window.googletag = {};
    }
    if (!window.googletag.cmd) {
      window.googletag.cmd = [];
    }
    this.gtagLoaded = new Promise((resolve, reject) => {
      setTimeout(reject, GTAG_TIMEOUT);
      window.googletag.cmd.push(resolve);
    });
    this.run(() => {
      const googleads = window.googletag.pubads();
      googleads.disableInitialLoad();
      googleads.enableSingleRequest();
      googleads.setPublisherProvidedId(this.kv.getPPID());

      window.googletag.enableServices();
    });
  }

  public createAdUnit(adUnit: AdUnit) {
    this.run(() => {
      const slot = window.googletag.defineSlot(
        this.getAdUnitCode(adUnit.id),
        adUnit.size,
        adUnit.id,
      ).addService(window.googletag.pubads());
      this.kv.getArray(adUnit).forEach(([k, v]) => v && slot.setTargeting(k, v));
      this.slotMap[adUnit.id] = slot;
      window.googletag.display(adUnit.id);
    });
  }

  public removeAdUnit(adUnit: AdUnit) {
    this.run(() => {
      window.googletag.destroySlots([this.slotMap[adUnit.id]]);
      delete this.slotMap[adUnit.id];
    });
  }

  public refresh(adUnitCodes: string[]) {
    this.gtagLoaded.catch(() => this.fallbackRender(adUnitCodes));
    this.run(() => {
      const slots = window.googletag.pubads().getSlots()
        .filter((s: any) => adUnitCodes.includes(s.getSlotElementId()))
        .map(this.updateRefreshCount.bind(this));
      window.googletag.pubads().refresh(slots);
    });
  }

  public getAdUnitCode(id: string) {
    return `/${this.buildNetworkCode()}/${this.params.inventoryCode}/${id}`;
  }

  public on(eventName: string, handler: Function) {
    this.run(() => {
      window.googletag.pubads().addEventListener(eventName, handler);
    });
  }

  protected fallbackRender(adUnitCodes: string[]) {
    if (!window.pbjs) {
      return;
    }

    const allBids = window.pbjs.getBidResponses() || {};

    adUnitCodes.forEach(c => {
      const container = document.getElementById(c);
      if (!container) return;

      container.innerHTML = '';
      const iframe = document.createElement('iframe');
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = '0';
      container.appendChild(iframe);

      if (!iframe.contentWindow) return;
      
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write('<head><style>body { margin: 0 }</style></head>');
      const bids = allBids[c]?.bids || [];
      if (bids.length > 0) {
        const bid = bids.sort((a: { cpm: number }, b: { cpm: number }) => b.cpm - a.cpm)[0];
        if (bid.cpm > 0.4) {
          window.pbjs.renderAd(doc, bid.adId, {});
          Eye.trackImpression('display', bid.adapterCode, bid.cpm, { gtagTimeout: true } as any);
        }
      }
    });
  }

  protected updateRefreshCount(s: any) {
    const refreshCount = parseInt(s.getTargetingMap().refreshCount);
    s.setTargeting('refreshCount', (refreshCount + 1 || 0).toString());
    return s;
  }

  protected run(cmd: () => void) {
    window.googletag.cmd.push(cmd);
  }

  protected buildNetworkCode() {
    return this.params.dfpNetworkCode + (this.params.mcm ? ',' + this.params.mcm : '');
  }
}


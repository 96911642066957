import { AdsService, AdUnit } from "./services/ads-service";
import { loadScript } from "./shared/loadScript";
import { shouldRunOutstream, shouldRunOwnOutstream } from "./shared/rules";
import { AdConfig } from "./shared/ad-config";

export class DisplayAd extends HTMLElement {
  protected coreService = AdsService.getInstance();
  protected adUnit: AdUnit;
  protected adQueueItem: any; 

  constructor() {
    super();
    this.adQueueItem = { adConfig: new AdConfig };
    this.adUnit = {} as any;
  }

  public connectedCallback() {
    // attributes may change after constructor
    this.adUnit = this.convertAttributesToAdUnit();
    this.innerHTML = `<div id="${this.adUnit.id}" style="width: 100%; height: 100%; position: relative;"></div>`;
    this.updateStyles(this.adUnit);
    this.coreService.registerAdUnit(this.adUnit);

    if (shouldRunOutstream(this.adUnit.id)) {
      if (shouldRunOwnOutstream()) {
        //this.runOutstream();
      } else {
        this.runRiseOutstream();
      }
    }
  }

  public disconnectedCallback() {
    this.coreService.unregisterAdUnit(this.convertAttributesToAdUnit());
  }

  public isViewable() {
    // This method is deprecated and will be removed in future
    return this.coreService.isAdUnitVisible(this.adUnit);
  }

  public refresh() {
    // This method is deprecated and will be removed in future
  }

  protected async runOutstream() {
    try {
      const child = document.getElementById(this.adUnit.id)!;
      child.style.display = 'none';
      const onFinish = () => { child.style.display = 'block' };
      await loadScript('https://ams.cdn.arkadiumhosted.com/advertisement/outstream/alpha/ark-outstream.js');
      const player = new window.ArkOutstream.Player({ name: 'outstream', parent: this });
      player.start(onFinish);
    } catch (e) {
      // do nothing :<
    }
  }

  protected runRiseOutstream() {
    try {
      loadScript('https://sdk.streamrail.com/wrapper/hb.loader.js?wrapper_id=61503de49bbbfe00016ebc3e&org=60d9ab656a43ad00010a56ed');
    } catch (e) {

    }
  }

  protected convertAttributesToAdUnit(): AdUnit {
    return {
      id: this.attr('data-is') || this.attr('data-id'),
      size: this.attr('dimensions', true),
      kv: this.attr('keyvalues', true),
      product: this.attr('product'),
      lastRefreshed: 0,
    };
  }

  protected attr(name: string, parse = false) {
    const value = this.attributes.getNamedItem(name)?.value;

    if (!value) return;
    if (!parse) return value;

    try {
      return JSON.parse(value || '');
    } catch (e) {
      console.warn(`Can't parse supplied "${name}" attribute.`);
    }
  }

  protected updateStyles(adUnit: AdUnit) {
    try {
      this.style.display = 'block';
      this.style.height = Math.max(...adUnit.size.map(([w, h]) => h)) + 'px';
      this.style.width = Math.max(...adUnit.size.map(([w, h]) => w)) + 'px';
    } catch (e) {

    }
  }
}

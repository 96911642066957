interface GDPR {
    enabled: boolean;
    personalizedAds: boolean;
}

export class AdConfig {
    __fields: any = {};

    constructor() {
        Object.keys(this)
            .forEach((key: string) => {
                this.__fields[key.toLowerCase()] = key;
            });
    }

    adUnit: string = '';

    bidsFilter: string = '';

    bidderTimeout: number = 3000; // prebid default is 3000

    destroyCreateRefresh: boolean = false;

    dfpNetworkCode: string = '100151972';

    dimensions: number[][] = [];

    enableRefresh: boolean = true;

    gaTracker?: string = undefined;

    gdpr: GDPR = {
        enabled: false,
        personalizedAds: false,
    };

    headerBidding: boolean = true;

    keyValues: any[] = [];

    kbaEnabled: boolean = true;

    refreshRate: number = 1;

    refreshTime: number = 31000;

    product: string = '';

    target: string = '';

    trackElementID: boolean = true;

    trackPagePath: boolean = false;

    trackWebView: boolean = false;

    viewableOnly: boolean = true ;

    viewabilityPercent: number = 25;

    fromJSON(options: any = {}) {
        for (let key in this) {
            if ([ 'fromJSON', '__fields', 'clone' ].includes(key)) continue;
            this[key] = options[key] || options[key.toLowerCase()] || this[key];
        }
    }

    clone() {
        const configClone = new AdConfig();
        configClone.fromJSON({ ...this });
        return configClone;
    }
}

declare var pbjs: any;

export class ReportingService {
  protected hasPressed = false;

  constructor() {
    window.addEventListener('keyup', this.onKeyWindowKeyDown.bind(this));
  }

  protected onKeyWindowKeyDown(event: KeyboardEvent) {
    if (event.key === '[') {
      if (this.hasPressed) {
        this.hasPressed = false;
        this.copyReport();
      } else {
        this.hasPressed = true;
        setTimeout(() => this.hasPressed = false, 3000);
      }
    }
  }

  protected copyReport() {
    const text = pbjs?.getAllPrebidWinningBids()
      .map((bid: any) => bid.adUnitCode + ': ' + [bid.adapterCode, bid.size, bid.creativeId, bid.meta?.advertiserName].join(', ')).join('\n');
    this.renderTooltip(text);
  }

  protected renderTooltip(text: string) {
    window.closeAdsDiag = () => document.querySelector('#ads_diag_container')?.remove();
    document.body.insertAdjacentHTML('beforeend', `
      <div id="ads_diag_container" style="position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; background: rgba(0, 0, 0, 0.7); display: flex;">
        <div style="width: 400px; height: 300px; border-radius: 5px; border: 1px solid black; background: white; margin: auto; padding: 1em;">
          <p style="color: black; display: flex; justify-content: space-between;">
            <span>Ads Diagnostic Data:</span>
            <small style="text-align: right; cursor: pointer" onclick="closeAdsDiag()">❌</small>
          </p>
          <textarea id="ads_diag_data">${text}</textarea>
          <small>Please, copy this data and send to Arkadium Support</small>
        </div>
      </div>
    `);
    (document.querySelector('#ads_diag_container textarea') as HTMLTextAreaElement)?.select()
  }

}

export interface IGeoResponse {
  ip: string;
  city: string;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
  metro_code: number;
  region_code: string;
  region_name: string;
  time_zone: string;
  zip_code: string;
}

export class GeoService {
  protected _geoPromise: Promise<IGeoResponse>;

  constructor() {
    const opts = { headers: { 'X-GEO-APP': 'ArkadiumAds' } };
    this._geoPromise = fetch('https://geoip.cdn.arkadiumhosted.com/json/', opts).then(response => response.json());
  }

  static getInstance(): GeoService {
    window.__ark_ads__ = window.__ark_ads__ || {};
    window.__ark_ads__.geo = window.__ark_ads__.geo || new GeoService();
    return window.__ark_ads__.geo;
  }

  public getGeo(): Promise<IGeoResponse | undefined> {
    return Promise.race([
      this._geoPromise,
      new Promise((resolve) => setTimeout(resolve, 1000)) as Promise<undefined>,
    ]);
  }
}

import { AdsService } from "./services/ads-service";

export class ArkGoogleTag {
  elements: any[] = [];

  coreService: AdsService = AdsService.getInstance();

  onShowEvent: any = null;

  commonAttrs: any = {};

  constructor(config: any = {}) {
    if (config.onShow && typeof config.onShow === 'function') {
      this.onShowEvent = (data: string[] = []) => config.onShow(data);
    }
    this.coreService.onShowGTMEvent = this.__onShow;
    this.commonAttrs.product = config.product;
  }

  get bidTimeout() {
    return 1200;
  }

  set bidTimeout(value: number) {
  }

  __onShow = (adUnitCodes: string[] = []) => {
    if (this.onShowEvent) {
      this.onShowEvent(adUnitCodes);
    }
  }

  add(config: { target: string, dimensions: number[][] }) {
    const el: any = document.createElement('display-ad-component');
    el.setAttribute('product', this.commonAttrs.product);
    el.setAttribute('data-id', config.target);
    el.setAttribute('dimensions', JSON.stringify(config.dimensions));
    el.componentId = config.target;
    this.elements.push(el);
  }

  refresh(ids: string[] = []) {
    this.elements.forEach((item: any) => {
      if (!item.parentNode) {
        console.warn('[refresh] WARNING item.parentNode falsey');
      }

      if (ids.length === 0 || ids.includes(item.externalComponentId || item.componentId)) {
        item.refresh();
      }
    });
  }

  show() {
    this.elements.forEach((el: any) => {
      if (el.parentNode) return;
      const target = el.componentId;
      const parent = document.getElementById(target);
      if (parent) {
        parent.removeAttribute('id');
        /* eslint-disable */
        el.externalComponentId = target;
        /* eslint-enable */
        parent.appendChild(el);
      }
    });
  }

  remove(ids: string[] = []) {
    const elements = ids.length === 0 ? this.elements : this.elements.filter(
      /* $FlowFixMe */
      el => ids.includes(el.externalComponentId || el.componentId),
    );

    elements.forEach((el) => {
      if (el.parentNode) {
        if (el.externalComponentId) {
          el.parentElement.setAttribute('id', el.externalComponentId);
        }
        /* $FlowFixMe */
        el.parentElement.removeChild(el);
      }
    });

    if (ids.length > 0) {
      this.elements = this.elements.filter(
        /* $FlowFixMe */
        el => !ids.includes(el.externalComponentId || el.componentId),
      );
    } else {
      this.elements = [];
    }
  }
}


import { init } from "./apstag";

interface AmazonAdUnit {
  slotID: string;
  slotName: string;
  sizes: number[][];
}

export class AmazonService {
  constructor(protected timeout = 2000) {
    init();
  }

  public requestBids(adUnits: AmazonAdUnit[]): Promise<void> {
    if (!window.apstag) return Promise.resolve();

    return new Promise((resolve) => {
      setTimeout(resolve, 1000);

      const onBidsFetched = () => {
        try {
          window.apstag.setDisplayBids();
        } catch (e) {}
        resolve();
      };

      const fetchBidsOptions = {
        slots: adUnits,
        timeout: this.timeout,
      };

      try {
        window.apstag.fetchBids(fetchBidsOptions, onBidsFetched)
          .catch(resolve);
      } catch (e) {
        resolve();
      }
    });
  }

  fetchBids(options: Object = {}): Promise<any> {
    return new Promise((resolve) => {
      console.log('AmazonService', 'options=' + !!options, 'apstag=' + !!window.apstag);
      /*@ts-ignore */
      if (options && !!window.apstag) {
        /*@ts-ignore */
        window.apstag.fetchBids(options, resolve);
      } else {
        resolve([]);
      }
    });
  }
}

// @ts-nocheck

export function init() {
  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) return;
    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }
    a[a9] = {
      init() {
        q("i", arguments);
      },
      fetchBids() {
        q("f", arguments);
      },
      setDisplayBids() {},
      targetingKeys() {
        return [];
      },
      _Q: [],
    };
    A = p.createElement(s);
    A.async = !0;
    A.src = t;
    g = p.getElementsByTagName(s)[0];
    g.parentNode && g.parentNode.insertBefore(A, g);
    window.apstag.init({
      pubID: "661990cc-08cb-45bb-9ee2-90c2a9034640",
      adServer: "googletag",
      videoAdServer: "DFP",
    });
  })("apstag", window, document, "script", "https://c.amazon-adsystem.com/aax2/apstag.js");
}


import { AdsService } from "./services/ads-service";
import { DisplayAd } from "./display-ad";
import { ArkGoogleTag } from "./legacy";
import { GeoService } from "./services/geo";
import { getHostname } from "./shared/get-hostname";

interface IArkAdsGlobal {
  geo?: GeoService;
  core?: AdsService;
  displayAd?: { }
  displayAdReady?: (fn: Function) => void;
}

declare global {
  interface Window {
    __ark_ads__: IArkAdsGlobal;
  }
}

window.__ark_ads__ = window.__ark_ads__ || {};
window.__ark_ads__.core = window.__ark_ads__.core || AdsService.getInstance(getHostname());
window.__ark_ads__.displayAd = window.__ark_ads__.displayAd || { ArkGoogleTag };
window.__ark_ads__.displayAdReady = (fn: Function) => { fn() };

defineCustomElement(DisplayAd);

function defineCustomElement(Component: CustomElementConstructor, customTag = 'display-ad-component') {
    if (!customElements.get(customTag)) {
        customElements.define(customTag, Component);
    }
}

import { loadScript } from '../shared/loadScript';

export class AdLightning {
  static instance?: AdLightning;

  static getInstance(boltiveId?: string): AdLightning {
    if (!this.instance) {
      this.instance = new AdLightning(boltiveId);
    }

    return this.instance;
  }

  protected _loaded: Promise<any>;

  constructor(boltiveId?: string) {
    this._loaded = boltiveId ? loadScript(`https://tagan.adlightning.com/${boltiveId}/op.js`) : Promise.resolve();
  }
}

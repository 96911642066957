function loadOriginal(url: string, timeout: number) {
  return new Promise(function (resolve, reject) {
      setTimeout(reject, timeout);
      var script = document.createElement('script');
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      (document.head || document.body).appendChild(script);
  });
}

function loadRetry(url: string, timeout: number) {
  var addParam = 'retry=' + Math.random();
  var delim = url.indexOf('?') === -1 ? '?' : '&';
  return loadOriginal(url + delim + addParam, timeout);
}

var Loader = {
  memo: {} as any,
  load: function (url: string, timeout: number) {
    function onError() {
      return loadRetry(url, timeout);
    }
    Loader.memo[url] = Loader.memo[url] || loadOriginal(url, timeout).catch(onError);
    return Loader.memo[url];
  },
};

const win = window as any;

win.__ark_ls = win.__ark_ls || Loader;

export function loadScript(url: string, timeout = 2000) {
  return win.__ark_ls.load(url, timeout);
}
